"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function SectionHero({ data }) {
  return (
    <div className="hero-section">
      <div className="hero-image-wrapper">
        <div className="image-content-wrapper">
          <h1>{data.Slides[0].title}</h1>
          <h3>{data.Slides[0].description}</h3>
          <Link href={data.Slides[0].button_one.location}>
            {data.Slides[0].button_one.text}
          </Link>
        </div>
        <Image
          src={data.Slides[0].image.data.attributes.url}
          alt={
            data.Slides[0].image.data.attributes.alternativeText ||
            "Timberline Clean Hero Image"
          }
          width={1920}
          height={1080}
        />
      </div>
    </div>
  );
}
