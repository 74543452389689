"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function SectionCards({ data }) {
  const cards = data.Cards;
  return (
    <div className="section-padded">
      <div className="cta-cards">
        {cards.map((card, index) => {
          return (
            <Link key={index} href={card.location}>
              <div className="cta-card">
                <div className="cta-card-image">
                  <Image
                    height={1080}
                    width={1920}
                    src={card.image.data.attributes.url}
                    alt={
                      card.image.data.attributes.alternativeText ||
                      "Timberline Clean Card Image"
                    }
                  />
                </div>
                <div className="card-content">
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                  <button>Learn More</button>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
