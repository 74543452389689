"use client";
import Image from "next/image";
import React from "react";
import card1 from "../../../../../public/assets/index/markets/Aviation.jpg";
import card2 from "../../../../../public/assets/index/markets/Education.jpg";
import card3 from "../../../../../public/assets/index/markets/Healthcare.jpg";
import card4 from "../../../../../public/assets/index/markets/Electronic Labs.jpg";

export default function SectionMarket({ data }) {
  return (
    <section className="section-market">
      <div className="left-column">
        <div className="section-title">
          <h1>Markets We Serve</h1>
        </div>
        <div className="markets-list">
          <ul>
            <li>Aviation</li>
            <li>Education</li>
            <li>Food and Beverage</li>
            <li>Government</li>
          </ul>
          <ul>
            <li>Healthcare</li>
            <li>Janitorial</li>
            <li>Manufacturing</li>
            <li>Municipalities</li>
          </ul>
          <ul>
            <li>Parking Facilities</li>
            <li>Retail and Hospitality</li>
            <li>Warehouse / Distribution</li>
          </ul>
        </div>
        <div className="markets-wrapper">
          <div className="box-wrapper">
            <Image src={card1} alt="cta-card-31" width={1920} height={1080} />
            <div className="market-wrapper-overlay">
              <h1>Aviation</h1>
            </div>
          </div>
          <div className="box-wrapper">
            <Image src={card4} alt="cta-card-31" width={1920} height={1080} />
            <div className="market-wrapper-overlay">
              <h1>Electronics Lab</h1>
            </div>
          </div>
          <div className="box-wrapper">
            <Image src={card2} alt="cta-card-31" width={1920} height={1080} />
            <div className="market-wrapper-overlay">
              <h1>Education</h1>
            </div>
          </div>
          <div className="box-wrapper">
            <Image src={card3} alt="cta-card-31" width={1920} height={1080} />
            <div className="market-wrapper-overlay">
              <h1>Healthcare</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="right-column">
        <iframe
          width="1236"
          height="695"
          src="https://www.youtube.com/embed/2b4RBq8wByo"
          title="This Is Timberline"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}
